@media (min-width: 576px) {
  .more_files {
    max-width: 94% !important;
  }
}

.img_item_section {
  flex-wrap: wrap;
}

.img_item_section .grabbable {
  flex: initial !important;
  width: 25% !important;
  cursor: move !important;
  padding: 4PX;
}

.img_item_section .grabbable img {
  width: 100%;
}

.large_modal {
  min-width: 90%
}

.form_group_box {
  height: 100%;
  display: flex;
  align-items: center;
}

.form_group_box .form-check {
  display: flex;
  align-items: center;
}

.form_group_box .form-check label {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .modal_form.modal-dialog {
    margin-right: auto;
    margin-left: auto;
    max-width: 700px !important;
  }
}


.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.controls {
  display: none;
}

.image_contain:hover .controls {
  display: block;
}

.copy_button {
  background-color: white;
  border-radius: 2px;
  padding: 1px 5px;
  cursor: pointer;
}

.iSAVrt .rdt_TableCol:first-child {
  width: 80px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  /* padding-left: 3px; */
  min-width: 25px;
}

.dwPlXY .ifdffW:first-child {
  width: 80px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  /* padding-left: 5px; */
  min-width: 25px;
}

.czCjuP .iDROoZ .htmfKR:first-child {
  max-width: 80px !important;
  min-width: 40px !important;
}

.jXIpD .kfVovw .drywwf:first-child {
  max-width: 80px !important;
  min-width: 40px !important;
  padding-right: 0 !important;
}

.editorClassName {
  height: 600px;
}


.permission-row li{
  width: calc(100%/5);
  padding: 2px 70px;
  border-bottom: 1px solid #080808;
}